import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Parse from 'parse';
import { BehaviorSubject, Observable, catchError, concatMap, from, of, switchMap, tap } from 'rxjs';

import { environment } from '../environments/environment';
import { DEFAULT_AUTH_ENDPOINTS, SERVER_STORAGE_NAME } from './consts';
import {
  AccountCreationRequest,
  AuthResponse,
  FPServer,
  LoginOptions,
  LoginResponse,
  ServerConfig
} from './interfaces';
import { getCloudFunctionUrl } from './gcf.utils';

@Injectable({ providedIn: 'root' })
export class AuthService {
  public fpServer: FPServer = null;
  private defaultAppVer: string = 'g';
  private serverConfig: ServerConfig = {
    authUrl: environment.authServerUrl,
    apiUrlOverride: null,
    webUrlOverride: null,
    appId: null,
    jsKey: null,
    serverName: null,
    authCloudUrl: environment.cloudAuthUrl,
    authDirect: false
  };

  public error$ = new BehaviorSubject<string>(null);

  /**
   * Used for debugging/testing on localhost.
   */
  public devAuthServer = new BehaviorSubject<string>(null);
  public devLoginServer = new BehaviorSubject<string>(null);
  public busy$ = new BehaviorSubject<boolean>(false);

  public readonly returnTo$ = new BehaviorSubject<string>(null);
  public readonly featureBase$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.initDev();
    this.init();
  }

  private initDev() {
    this.devAuthServer.next(localStorage.getItem('devAuthServer'));
    this.devLoginServer.next(localStorage.getItem('devLoginServer'));

    this.route.queryParams.subscribe((params) => {
      if (params['authServer']) {
        this.devAuthServer.next(params['authServer']);
        localStorage.setItem('devAuthServer', params['authServer']);
      }
      if (params['loginServer']) {
        this.devLoginServer.next(params['loginServer']);
        localStorage.setItem('devLoginServer', params['loginServer']);
      }
    });
  }

  private init() {
    this.busy$.next(true);
    // Check if server details are known.
    const fpServer = localStorage.getItem(SERVER_STORAGE_NAME);
    if (!fpServer) {
      this.getCustomServerConfig()
        .pipe(
          catchError((error) => {
            console.error('Unable to get server config', error);
            this.busy$.next(false);
            this.error$.next('Unable to get server config');
            return of(null);
          })
        )
        .subscribe(() => {
          this.busy$.next(false);
        });
      return;
    }
    let serverDetails: FPServer;
    try {
      serverDetails = JSON.parse(fpServer);
    } catch (e) {
      // Failed to read, clear local storage and continue.
      this.clearUserSessionData();
      this.busy$.next(false);
      return;
    }
    this.fpServer = serverDetails;

    // We have server details, check if we have a valid session token.
    const parseKey = `Parse/${serverDetails.appId}/currentUser`;
    const userDetails = localStorage.getItem(parseKey);
    if (!userDetails) {
      // No session, clear local storage and continue.
      this.clearUserSessionData();
      this.busy$.next(false);
      return;
    }

    // If here, we have both server details and a session.

    // Check that users session is valid.
    this.redirectIfLoggedIn().then((_) => {
      this.busy$.next(false);
    });
  }

  private initializeParse() {
    Parse.initialize(this.fpServer.appId, this.fpServer.jsKey);
    const loginServer = this.getLoginServer();
    let parseServerUrl = loginServer;
    if (
      loginServer.includes('localhost') ||
      (this.serverConfig.apiUrlOverride && this.serverConfig.apiUrlOverride.endsWith('/1'))
    ) {
      parseServerUrl += '/1/';
    }
    (Parse.serverURL as any) = parseServerUrl;
  }

  private redirectToAppVer() {
    if (!this.fpServer || !this.fpServer.appVer) {
      console.warn('Invalid fpServer, clearing session.');
      this.clearUserSessionData();
      return;
    }
    if (!this.handleOtherFeature()) {
      const redirectUrl = `/${this.fpServer.appVer}/`;
      window.location.href = redirectUrl;
      return;
    }

    // Handle other features
    // == FeatureBase SSO ==
    if (this.featureBase$.value) {
      const returnTo = this.returnTo$.value || 'https://feedback.gofreshprojects.com/';
      Parse.Cloud.run('featurebase_sso').then((response) => {
        const url = `https://feedback.gofreshprojects.com/api/v1/auth/access/jwt?jwt=${response}&return_to=${returnTo}`;
        window.location.href = url;
      });
    }
  }

  private handleOtherFeature() {
    if (this.featureBase$.value) {
      return true;
    }
    return false;
  }

  /**
   * Checks for an existing Parse User session.
   * Will talk to server to verify session is still valid.
   * If for any reason the session is invalid, the user will be logged out.
   *
   * We refresh the page to clear the Parse.initialize in case the user wants to change email address (and therefore server)
   * @returns
   */
  private redirectIfLoggedIn(): Promise<boolean> {
    this.initializeParse();
    if (!Parse.User.current()) {
      this.clearUserSessionData();
      window.location.href = window.location.origin + window.location.pathname;
      return Promise.resolve(false);
    }

    return Parse.Session.current()
      .then((session) => {
        // Valid session
        this.redirectToAppVer();
        return true;
      })
      .catch((error) => {
        // Invalid session or server details.  Clear data and refresh.
        this.clearUserSessionData();
        window.location.href = window.location.origin + window.location.pathname;
        return false;
      });
  }

  private attemptToLogin(sessionToken: string) {
    this.initializeParse();

    // We got a session token, log into parse.
    return Parse.User.become(sessionToken)
      .then((s) => {
        if (!s) {
          console.warn('Unable to log in');
          const error = 'Unable to log in.';
          this.clearUserSessionData();
          window.location.href = window.location.origin + window.location.pathname + '?error=' + error;
          return null;
        }
        // Get appVer from server
        if (!this.fpServer.appVer && s.get('appVer')) {
          this.fpServer.appVer = s.get('appVer');
        } else if (!this.fpServer.appVer) {
          // set default appVer
          this.fpServer.appVer = 'h';
        }

        // Save fpServer details for future
        if (!environment.dummyLogin) {
          localStorage.setItem(SERVER_STORAGE_NAME, JSON.stringify(this.fpServer));
          this.redirectToAppVer();
        } else {
          console.info('DEBUG', 'Would save FPServer to localStorage', this.fpServer);
          console.info('DEBUG', 'Would redirect to ', this.fpServer.appVer);
          this.busy$.next(false);
        }
        return s;
      })
      .catch((e) => {
        this.busy$.next(false);
        console.error('Unable to log in', e);
        return { error: 'Invalid username or password.' };
      });
  }

  public clearDevAuthServer() {
    this.devAuthServer.next(null);
    localStorage.removeItem('devAuthServer');
  }

  public clearDevLoginServer() {
    this.devLoginServer.next(null);
    localStorage.removeItem('devLoginServer');
  }

  public getAuthServer() {
    // Developer test overrides
    if (this.devAuthServer.value) {
      if (this.devAuthServer.value.endsWith('/')) {
        return this.devAuthServer.value.slice(0, -1);
      }
      return this.devAuthServer.value;
    }
    // Custom config loaded from hosting
    if (this.serverConfig.authUrl) {
      return this.removeTrailingSlash(this.serverConfig.authUrl);
    }

    // Default hardcoded from environment file
    return this.removeTrailingSlash(environment.authServerUrl);
  }

  private removeTrailingSlash(url) {
    if (url.endsWith('/')) {
      return url.slice(0, -1);
    }
    return url;
  }

  public getLoginServer() {
    if (this.devLoginServer.value) {
      if (this.devLoginServer.value.endsWith('/')) {
        return this.devLoginServer.value.slice(0, -1);
      }
      return this.devLoginServer.value;
    }
    // Custom config loaded from hosting
    if (this.serverConfig.webUrlOverride) {
      return this.serverConfig.webUrlOverride;
    }

    if (this.fpServer.apiUrl.endsWith('/')) {
      return this.fpServer.apiUrl.slice(0, -1);
    }

    return this.fpServer.apiUrl;
  }

  public fetchServerDetailsById(serverName: string) {
    const url = `${this.serverConfig.authCloudUrl}/${getCloudFunctionUrl('server')}?serverName=${serverName}`;
    return this.http.get<any>(url).pipe(
      tap((response) => {
        this.fpServer = response.server;
        this.applyCustomConfigToFpServer();
      })
    );
  }

  private applyCustomConfigToFpServer() {
    if (this.serverConfig) {
      if (this.serverConfig.apiUrlOverride) {
        this.fpServer.apiUrl = this.serverConfig.apiUrlOverride;
      }
      if (this.serverConfig.webUrlOverride) {
        this.fpServer.webUrl = this.serverConfig.webUrlOverride;
      }
      if (this.serverConfig.appId) {
        this.fpServer.appId = this.serverConfig.appId;
      }
      if (this.serverConfig.jsKey) {
        this.fpServer.jsKey = this.serverConfig.jsKey;
      }
      if (this.serverConfig.serverName) {
        this.fpServer.name = this.serverConfig.serverName;
      }
      if (this.serverConfig.appVer) {
        this.fpServer.appVer = this.serverConfig.appVer;
      }
    }
  }

  public fetchUserSessionToken(username: string, password: string) {
    const url = `${this.getLoginServer()}/auth/login`;
    return this.http.post(url, { username, password }).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status >= 400 && error.status < 500) {
          console.error('Invalid username or password', error);
          return of({ token: null, error: `(${error.status}) Invalid username or password` });
        }
        throw error;
      })
    );
  }

  /**
   * Clears fpServer and any related data.
   * To be used on errors to ensure a clean config
   */
  public clearLoginData() {
    if (this.fpServer) {
      this.clearUserSessionData();
    }
    localStorage.removeItem(SERVER_STORAGE_NAME);
  }

  public clearUserSessionData() {
    localStorage.removeItem(SERVER_STORAGE_NAME);
    if (this.fpServer && this.fpServer.appId) {
      const parseKey = `Parse/${this.fpServer.appId}/currentUser`;
      localStorage.removeItem(parseKey);
    }
  }

  public forgotPassword(username: string) {
    this.busy$.next(true);
    return this.forgotPasswordRequest(username).pipe(
      tap((response: any) => {
        if (response.error) {
          // Let catchError handle the error.
          throw new Error(response.error);
        }
      }),
      catchError((error) => {
        this.busy$.next(false);
        return of({ token: null, error });
      }),
      tap(() => {
        this.busy$.next(false);
      })
    );
  }

  private forgotPasswordRequest(username: string) {
    const url = this.serverConfig.authCloudUrl + '/' + getCloudFunctionUrl('forgotpass');
    const request = {
      host: window.location.host,
      username: username,
      href: window.location.href
    };
    return this.http.post(url, request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of({ token: null, error: error.error });
        }
        throw error;
      })
    );
  }

  public validateResetCode(username: string, code: string) {
    const url = this.serverConfig.authCloudUrl + '/' + getCloudFunctionUrl('resetvalidate');
    const request = {
      username: username,
      code: code
    };
    return this.http.post(url, request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of({ token: null, error: error.error });
        }
        throw error;
      })
    );
  }

  public resetPassword(username: string, code: string, password: string) {
    const url = this.serverConfig.authCloudUrl + '/' + getCloudFunctionUrl('resetpass');
    const request = {
      username: username,
      code: code,
      password: password
    };
    return this.http.post(url, request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 404) {
          return of({ token: null, error: error.error });
        }
        throw error;
      })
    );
  }

  public createAccount(request: AccountCreationRequest) {
    return this.fetchServerDetailsById(request.server).pipe(
      concatMap(() => {
        if (this.fpServer.parsePath && !this.fpServer.apiUrl.endsWith('/' + this.fpServer.parsePath)) {
          this.fpServer.apiUrl += '/' + this.fpServer.parsePath;
        }
        localStorage.setItem(SERVER_STORAGE_NAME, JSON.stringify(this.fpServer));
        const url = `${this.getApiUrlWithoutParse()}/auth/create-account`;
        return this.http.post(url, request, { responseType: 'text' });
      }),
      tap((response: any) => {
        this.attemptToLogin(response);
      })
    );
  }

  private getApiUrlWithoutParse() {
    if (this.fpServer.parsePath) {
      return this.fpServer.apiUrl.replace(`/${this.fpServer.parsePath}`, '');
    } else if (this.fpServer.apiUrl.endsWith('/1')) {
      return this.fpServer.apiUrl.slice(0, -2);
    }
    return this.fpServer.apiUrl;
  }

  /**
   * Looks for custom server config from a path on current url or from environment
   */
  public getCustomServerConfig() {
    if (!environment.authServerUrl || environment.serverConfig) {
      let configUrl;
      // For testing purposes
      if (environment.serverConfig) {
        for (const key in environment.serverConfig) {
          if (environment.serverConfig[key]) {
            this.serverConfig[key] = environment.serverConfig[key];
          }
        }
        return of(environment.serverConfig);
      }

      if (environment.configUrl) {
        configUrl = environment.configUrl;
      } else if (environment.configUrlLocalhost && window.location.hostname === 'localhost') {
        configUrl = environment.configUrlLocalhost;
      } else {
        configUrl = `${window.location.origin}/config/auth-server`;
      }

      // Request custom config.
      return this.http.get(configUrl).pipe(
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 404) {
            return of({ token: null, error: error.error });
          }
          throw error;
        }),
        tap((config: ServerConfig) => {
          for (const key in config) {
            if (config[key]) {
              this.serverConfig[key] = config[key];
            }
          }
          console.info('Using custom config');
        })
      );
    } else {
      this.serverConfig.authUrl = this.getAuthServer();
      return of(null);
    }
  }

  // notes: redirects if successful or returns response.error
  public login(username: string, password: string, mfa?: any, options?: LoginOptions): Observable<any> {
    this.busy$.next(true);
    if (this.serverConfig.authDirect) {
      return this.loginDirect(username, password);
    }
    const url = this.serverConfig.authCloudUrl + '/' + getCloudFunctionUrl('login');
    let request: any = { username, password };
    if (mfa) {
      request = { ...request, mfa };
    }
    if (options) {
      if (options.skipMfa) {
        request.mfaSkip = options.skipMfa;
      }
    }
    return this.http.post(url, request).pipe(
      switchMap((response: AuthResponse) => {
        if (response.mfaChallenge) {
          return of({
            mfaChallenge: response.mfaChallenge
          });
        }

        this.fpServer = {
          apiUrl: response.server.apiUrl,
          externalApiUrl: response.server.externalApiUrl,
          appId: response.server.appId,
          appVer: response.appVer,
          flag: '',
          id: response.server._id,
          jsKey: response.server.jsKey,
          name: response.server.name,
          secondaryUrl: '',
          liveServer: window.location.origin,
          webUrl: window.location.origin,
          parsePath: response.server.parsePath
        };
        if (this.fpServer.parsePath && !this.fpServer.apiUrl.endsWith('/' + this.fpServer.parsePath)) {
          this.fpServer.apiUrl += '/' + this.fpServer.parsePath;
        }
        this.applyCustomConfigToFpServer();
        return from(this.attemptToLogin(response.sessionToken));
      }),
      catchError((error) => {
        this.busy$.next(false);
        if (error.error) {
          return of({ token: null, error: error.error });
        } else if (error.status === 401) {
          return of({ token: null, error: 'Invalid username or password' });
        } else {
          return of({ token: null, error });
        }
      })
    );
  }

  /**
   * Direct login to Parse, bypass cloud functions.
   * @param username
   * @param password
   * @returns
   */
  public loginDirect(username: string, password: string): Observable<any> {
    this.busy$.next(true);
    this.fpServer = {
      apiUrl: '',
      externalApiUrl: '',
      id: '',
      name: '',
      secondaryUrl: '',
      appId: '',
      jsKey: '',
      flag: '',
      appVer: '',
      liveServer: '',
      webUrl: ''
    };
    this.applyCustomConfigToFpServer();

    return this.fetchUserSessionToken(username, password).pipe(
      tap((response: any) => {
        if (response.error) {
          // Let catchError handle the error.
          throw new Error(response.error);
        }
        this.attemptToLogin(response.token);
      }),
      catchError((error) => {
        this.busy$.next(false);
        return of({ token: null, error });
      }),
      tap(() => {
        this.busy$.next(false);
      })
    );
  }
}
