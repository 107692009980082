import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { BehaviorSubject } from 'rxjs';

import { AuthService } from './auth.service';

type DISPLAY_MODE = 'login' | 'resetpassword' | 'register' | 'migrating';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public appVer = '{{ BUILD }}';
  public devAuthServer = this.authService.devAuthServer;
  public devLoginServer = this.authService.devLoginServer;

  public allowSmallScreens$ = new BehaviorSubject<boolean>(false);

  public mode$ = new BehaviorSubject<DISPLAY_MODE>('login');
  public error$ = this.authService.error$;

  public constructor(private route: ActivatedRoute, private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    // Wait for routing to settle before checking for query params
    this.router.events.subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        const params = this.route.snapshot.queryParams;
        const data = this.getChildData(this.route.snapshot);
        if (data.allowSmallScreens === true || data.allowSmallScreens === false) {
          this.allowSmallScreens$.next(data.allowSmallScreens);
        }

        if (params['reset']) {
          this.mode$.next('resetpassword');
          return;
        }
        if (params['onboarding'] && params['onboarding'] === 'go') {
          this.mode$.next('register');
          return;
        }
        if (params['migrating']) {
          this.mode$.next('migrating');
          // clear the query params
          this.router.navigate([], { queryParams: {} });
          return;
        }

        if (params['return_to']) {
          this.authService.returnTo$.next(params['return_to']);
        }

        if (data && data['feature'] === 'featurebase') {
          this.authService.featureBase$.next(true);
        }
      }
    });
  }

  // Recursively get all child data and combine
  private getChildData(snapshot) {
    const data = snapshot.data;
    let childData = {};
    if (snapshot.firstChild) {
      childData = this.getChildData(snapshot.firstChild);
    }
    return { ...data, ...childData };
  }

  public onClearDevAuthServer() {
    this.authService.clearDevAuthServer();
  }
  public onClearDevLoginServer() {
    this.authService.clearDevLoginServer();
  }

  public onSwitchToLogin() {
    this.mode$.next('login');
  }
}
