import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject, catchError, of } from 'rxjs';

import { AuthService } from '../auth.service';
import { AccountCreationRequest } from '../interfaces';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.scss']
})
export class CreateAccountComponent implements OnInit {
  public busy$ = new BehaviorSubject<boolean>(false);
  public error$ = new BehaviorSubject<string>(null);
  public success$ = new BehaviorSubject<boolean>(false);
  public showServer$ = new BehaviorSubject<boolean>(false);

  public formGroup = this.getFormGroup();

  public isLocalhost = window.location.hostname === 'localhost';

  constructor(private route: ActivatedRoute, private authService: AuthService, private formBuilder: FormBuilder) {}

  ngOnInit() {
    const params = this.route.snapshot.queryParams;
    if (params['servers']) {
      this.showServer$.next(true);
    }
    if (params['refer']) {
      this.formGroup.patchValue({
        user: {
          refer: params['refer']
        }
      });
    }
    if (params['refCode']) {
      this.formGroup.patchValue({
        user: {
          refCode: params['refCode']
        }
      });
    }
    if (params['locale']) {
      this.formGroup.patchValue({
        user: {
          locale: params['locale']
        }
      });
    }
  }

  private getFormGroup() {
    return this.formBuilder.group({
      user: new FormGroup({
        name: new FormControl('', [Validators.required]),
        company: new FormControl('', [Validators.required]),
        email: new FormControl('', [Validators.required, Validators.email]),
        password: new FormControl('', [Validators.required, Validators.minLength(10)]),
        locale: new FormControl('UK', []),
        refer: new FormControl('', []),
        refCode: new FormControl('', []),
        server: new FormControl('global-uk', [])
      }),
      poc: new FormControl(true, []),
      termsAccepted: new FormControl(false, [Validators.requiredTrue])
    });
  }

  onSubmit() {
    this.busy$.next(true);
    const names = this.formGroup.value.user.name.split(' ');
    const request: AccountCreationRequest = {
      firstName: names[0],
      lastName: names.length > 1 ? names[1] : '',
      emailAddress: this.formGroup.value.user.email,
      locale: this.formGroup.value.user.locale,
      password: this.formGroup.value.user.password,
      companyName: this.formGroup.value.user.company,
      refer: 'FreshLogin',
      refCode: 'FreshLogin',
      server: this.formGroup.value.user.server,
      poc: this.formGroup.value.poc
    };
    this.authService
      .createAccount(request)
      .pipe(
        catchError((err) => {
          this.busy$.next(false);
          this.error$.next(err);
          return of({ error: err.error });
        })
      )
      .subscribe((response) => {
        if (response['error']) {
          this.busy$.next(false);
          this.error$.next(response['error']);
        }
      });
  }
}
