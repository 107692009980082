export function renderFreshProjectsSvgToConsole() {
  const svg = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 698 192" height="192px" width="698px" id="svg2" version="1.1" preserveAspectRatio="xMidYMid slice">
    <defs id="defs6">
      <clipPath id="clipPath18" clipPathUnits="userSpaceOnUse">
        <path id="path16" d="M 0,595.276 H 595.276 V 0 H 0 Z"/>
      </clipPath>
    </defs>
    <g transform="matrix(1.333333, 0, 0, -1.333333, -53.881176, 793.701355)" id="g10">
      <g id="g12" transform="matrix(1, 0, 0, 1, 0, 227.155548)">
        <g clip-path="url(#clipPath18)" id="g14">
          <g transform="translate(106.5829,249.2926)" id="g20">
            <path id="path22" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c -24.707,2.223 -44.073,22.975 -44.073,48.26 0,26.768 21.7,48.468 48.468,48.468 25.286,0 46.039,-19.366 48.26,-44.073 h 14.637 c -2.258,32.778 -29.545,58.673 -62.897,58.673 -34.831,0 -63.067,-28.237 -63.067,-63.068 0,-33.352 25.895,-60.639 58.673,-62.897 z"/>
          </g>
          <g transform="translate(159.2381,293.1588)" id="g24">
            <path id="path26" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 C -2.092,-23.259 -20.607,-41.774 -43.866,-43.866 V -58.503 C -12.541,-56.345 12.479,-31.325 14.637,0 Z"/>
          </g>
          <g transform="translate(210.5292,284.8434)" id="g28">
            <path id="path30" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 V 65.819 H 43.848 V 56.642 H 10.382 V 36.988 H 38.378 V 28.366 H 10.382 L 10.382,0 Z"/>
          </g>
          <g transform="translate(288.4901,316.9186)" id="g32">
            <path id="path34" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 h 18.17 c 1.482,0 2.828,0.324 4.033,0.972 1.205,0.65 2.238,1.545 3.105,2.69 0.865,1.143 1.544,2.456 2.039,3.939 0.494,1.484 0.742,3.059 0.742,4.729 0,1.668 -0.295,3.243 -0.881,4.727 -0.588,1.483 -1.359,2.78 -2.317,3.893 -0.958,1.112 -2.071,1.993 -3.337,2.643 -1.268,0.648 -2.582,0.973 -3.94,0.973 L 0,24.566 Z m -10.382,-32.075 v 65.819 h 28.645 c 2.966,0 5.7,-0.619 8.203,-1.855 2.504,-1.237 4.65,-2.859 6.443,-4.866 1.793,-2.009 3.198,-4.297 4.218,-6.86 1.02,-2.566 1.529,-5.178 1.529,-7.833 0,-2.226 -0.309,-4.358 -0.926,-6.397 C 37.112,3.893 36.246,2.007 35.134,0.278 34.021,-1.453 32.662,-2.953 31.056,-4.218 29.448,-5.486 27.687,-6.429 25.771,-7.046 L 41.253,-32.075 H 29.479 L 15.203,-9.179 H 0 v -22.896 z"/>
          </g>
          <g transform="translate(398.4354,294.0202)" id="g36">
            <path id="path38" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 V -9.177 H -45.146 V 56.642 H -0.835 V 47.465 H -34.764 V 28.739 H -5.377 V 20.117 H -34.764 V 0 Z"/>
          </g>
          <g transform="translate(460.6376,335.1808)" id="g40">
            <path id="path42" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c -0.618,0.679 -1.515,1.405 -2.688,2.178 -1.173,0.772 -2.548,1.499 -4.125,2.179 -1.576,0.679 -3.307,1.236 -5.191,1.668 -1.887,0.432 -3.817,0.649 -5.795,0.649 -4.388,0 -7.617,-0.803 -9.686,-2.409 -2.073,-1.608 -3.106,-3.833 -3.106,-6.675 0,-1.608 0.323,-2.921 0.974,-3.941 0.649,-1.02 1.652,-1.916 3.013,-2.688 1.358,-0.773 3.058,-1.453 5.097,-2.039 2.04,-0.588 4.418,-1.221 7.14,-1.901 3.337,-0.804 6.348,-1.7 9.038,-2.688 2.687,-0.989 4.959,-2.194 6.813,-3.615 1.854,-1.423 3.291,-3.168 4.311,-5.238 1.018,-2.071 1.529,-4.589 1.529,-7.556 0,-3.337 -0.634,-6.21 -1.9,-8.62 -1.268,-2.412 -2.998,-4.374 -5.191,-5.887 -2.196,-1.516 -4.745,-2.628 -7.648,-3.338 -2.905,-0.709 -6.026,-1.066 -9.364,-1.066 -5.005,0 -9.842,0.756 -14.508,2.271 -4.666,1.513 -8.884,3.723 -12.653,6.629 l 4.727,8.899 c 0.802,-0.804 1.961,-1.701 3.477,-2.688 1.514,-0.989 3.275,-1.901 5.284,-2.736 2.007,-0.833 4.185,-1.544 6.535,-2.131 2.349,-0.589 4.79,-0.881 7.324,-0.881 4.078,0 7.231,0.71 9.455,2.133 2.226,1.421 3.338,3.523 3.338,6.303 0,1.669 -0.417,3.06 -1.252,4.172 -0.834,1.112 -2.025,2.086 -3.568,2.921 -1.546,0.833 -3.43,1.589 -5.655,2.271 -2.225,0.678 -4.76,1.39 -7.602,2.132 -3.277,0.864 -6.135,1.776 -8.575,2.734 -2.443,0.958 -4.466,2.117 -6.071,3.476 -1.609,1.359 -2.829,2.95 -3.664,4.774 -0.833,1.822 -1.251,4.033 -1.251,6.628 0,3.213 0.618,6.073 1.855,8.576 1.235,2.503 2.921,4.603 5.053,6.304 2.131,1.699 4.648,2.981 7.553,3.846 2.906,0.865 6.057,1.298 9.457,1.298 4.45,0 8.559,-0.711 12.33,-2.132 3.767,-1.422 7.076,-3.183 9.919,-5.285 z"/>
          </g>
          <g transform="translate(546.2958,350.6623)" id="g44">
            <path id="path46" style="fill:#79b935;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 v -65.819 h -10.477 v 29.016 H -43.663 V -65.819 H -54.045 V 0 h 10.382 v -27.533 h 33.186 V 0 Z"/>
          </g>
          <g transform="translate(212.5897,257.3327)" id="g48">
            <path id="path50" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 h 9.909 c 0.945,0 1.793,0.196 2.547,0.588 0.755,0.391 1.397,0.915 1.921,1.577 0.526,0.66 0.931,1.415 1.215,2.265 0.282,0.848 0.424,1.731 0.424,2.648 0,0.944 -0.168,1.84 -0.506,2.689 -0.337,0.85 -0.795,1.598 -1.375,2.245 -0.58,0.648 -1.253,1.159 -2.022,1.537 -0.767,0.378 -1.584,0.566 -2.446,0.566 H 0 Z M -2.061,-12.738 V 15.976 H 9.747 c 1.214,0 2.331,-0.256 3.357,-0.769 1.025,-0.512 1.908,-1.192 2.65,-2.043 0.74,-0.848 1.32,-1.807 1.738,-2.871 0.417,-1.064 0.627,-2.137 0.627,-3.215 0,-1.159 -0.196,-2.277 -0.587,-3.356 C 17.141,2.644 16.588,1.686 15.874,0.851 15.159,0.015 14.303,-0.652 13.307,-1.152 12.309,-1.65 11.203,-1.898 9.99,-1.898 H 0 v -10.84 z"/>
          </g>
          <g transform="translate(257.9257,257.3327)" id="g52">
            <path id="path54" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 h 10.07 c 0.916,0 1.753,0.204 2.507,0.608 0.755,0.405 1.402,0.937 1.942,1.597 0.538,0.66 0.95,1.415 1.234,2.266 0.282,0.847 0.424,1.718 0.424,2.607 0,0.916 -0.169,1.8 -0.506,2.649 -0.338,0.85 -0.794,1.598 -1.375,2.245 -0.58,0.646 -1.254,1.167 -2.023,1.556 -0.768,0.391 -1.596,0.587 -2.487,0.587 l -9.786,0 z m -2.062,-12.738 v 28.714 h 11.93 c 1.213,0 2.331,-0.256 3.356,-0.769 1.024,-0.512 1.907,-1.192 2.649,-2.043 0.742,-0.848 1.321,-1.807 1.739,-2.871 C 18.03,9.229 18.24,8.156 18.24,7.078 18.24,6.027 18.078,5.009 17.754,4.024 17.43,3.04 16.972,2.157 16.379,1.377 15.785,0.595 15.071,-0.054 14.236,-0.564 13.4,-1.078 12.483,-1.414 11.485,-1.576 l 7.16,-11.162 H 16.258 L 9.302,-1.898 H 0 v -10.84 z"/>
          </g>
          <g transform="translate(302.411,258.9518)" id="g56">
            <path id="path58" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c 0,-1.645 0.275,-3.229 0.828,-4.752 0.553,-1.523 1.335,-2.872 2.347,-4.044 1.011,-1.173 2.217,-2.109 3.618,-2.811 1.402,-0.701 2.939,-1.051 4.612,-1.051 1.725,0 3.282,0.364 4.67,1.093 1.389,0.726 2.573,1.69 3.558,2.89 0.984,1.199 1.747,2.554 2.285,4.065 0.54,1.508 0.811,3.046 0.811,4.61 0,1.645 -0.285,3.228 -0.851,4.752 -0.566,1.522 -1.355,2.871 -2.365,4.044 -1.011,1.173 -2.205,2.109 -3.579,2.809 -1.375,0.703 -2.886,1.053 -4.529,1.053 -1.726,0 -3.291,-0.364 -4.692,-1.093 C 5.311,10.837 4.111,9.873 3.113,8.674 2.116,7.474 1.348,6.119 0.808,4.61 0.27,3.1 0,1.562 0,0 m 11.363,-14.56 c -1.995,0 -3.815,0.419 -5.459,1.253 -1.644,0.837 -3.061,1.929 -4.246,3.278 -1.186,1.346 -2.102,2.897 -2.751,4.65 -0.646,1.753 -0.97,3.544 -0.97,5.379 0,1.913 0.344,3.747 1.032,5.5 0.686,1.752 1.631,3.302 2.83,4.65 1.2,1.348 2.623,2.42 4.267,3.216 1.644,0.794 3.424,1.193 5.339,1.193 1.993,0 3.82,-0.424 5.479,-1.275 1.658,-0.848 3.073,-1.961 4.246,-3.336 1.173,-1.374 2.083,-2.931 2.73,-4.672 0.647,-1.738 0.971,-3.498 0.971,-5.276 0,-1.941 -0.344,-3.788 -1.032,-5.541 -0.687,-1.753 -1.631,-3.296 -2.831,-4.63 -1.199,-1.335 -2.621,-2.401 -4.267,-3.195 -1.644,-0.795 -3.423,-1.194 -5.338,-1.194"/>
          </g>
          <g transform="translate(349.1219,247.5885)" id="g60">
            <path id="path62" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c 0.673,-0.46 1.483,-0.83 2.426,-1.113 0.944,-0.283 1.995,-0.424 3.155,-0.424 1.563,0 2.804,0.282 3.72,0.849 0.917,0.566 1.619,1.388 2.103,2.466 0.486,1.079 0.802,2.406 0.95,3.985 0.149,1.576 0.222,3.361 0.222,5.358 V 25.72 H 14.64 V 11.121 c 0,-2.184 -0.095,-4.174 -0.283,-5.965 -0.19,-1.795 -0.6,-3.324 -1.234,-4.591 -0.635,-1.267 -1.543,-2.252 -2.729,-2.953 -1.187,-0.7 -2.791,-1.05 -4.813,-1.05 -2.427,0 -4.504,0.592 -6.228,1.779 z"/>
          </g>
          <g transform="translate(410.2675,246.4557)" id="g64">
            <path id="path66" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="M 0,0 V -1.861 H -19.127 V 26.853 H -0.362 V 24.992 H -17.065 V 13.709 h 14.6 V 11.93 h -14.6 V 0 Z"/>
          </g>
          <g transform="translate(433.5223,259.154)" id="g68">
            <path id="path70" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c 0,1.725 0.304,3.443 0.91,5.156 0.607,1.711 1.489,3.241 2.65,4.59 1.159,1.348 2.581,2.447 4.266,3.296 1.685,0.849 3.606,1.274 5.763,1.274 2.56,0 4.744,-0.586 6.551,-1.76 1.806,-1.172 3.155,-2.675 4.044,-4.508 L 22.486,7.077 c -0.513,0.997 -1.112,1.833 -1.8,2.507 -0.687,0.675 -1.429,1.22 -2.223,1.639 -0.796,0.417 -1.625,0.72 -2.487,0.909 -0.865,0.188 -1.713,0.284 -2.549,0.284 -1.833,0 -3.451,-0.372 -4.853,-1.113 C 7.172,10.561 5.986,9.599 5.015,8.412 4.044,7.226 3.31,5.885 2.812,4.388 2.312,2.892 2.063,1.389 2.063,-0.122 c 0,-1.699 0.296,-3.316 0.89,-4.853 0.593,-1.537 1.401,-2.891 2.426,-4.064 1.024,-1.172 2.238,-2.11 3.64,-2.811 1.402,-0.7 2.913,-1.05 4.53,-1.05 0.862,0 1.752,0.106 2.669,0.322 0.916,0.216 1.793,0.553 2.629,1.013 0.835,0.458 1.617,1.044 2.345,1.758 0.728,0.714 1.334,1.557 1.819,2.528 l 1.781,-0.849 c -0.486,-1.08 -1.146,-2.037 -1.983,-2.872 -0.836,-0.836 -1.765,-1.536 -2.789,-2.103 -1.027,-0.566 -2.111,-0.992 -3.256,-1.274 -1.147,-0.284 -2.273,-0.425 -3.377,-0.425 -1.969,0 -3.768,0.431 -5.399,1.293 -1.631,0.864 -3.04,1.989 -4.226,3.378 C 2.575,-8.742 1.651,-7.165 0.991,-5.398 0.33,-3.634 0,-1.835 0,0"/>
          </g>
          <g transform="translate(503.4042,271.4479)" id="g72">
            <path id="path74" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 h -10.515 v -26.854 h -2.061 V 0 H -23.091 V 1.86 H 0 Z"/>
          </g>
          <g transform="translate(544.7772,268.5358)" id="g76">
            <path id="path78" style="fill:#444443;fill-opacity:1;fill-rule:nonzero;stroke:none" d="m 0,0 c -1.86,2.075 -4.49,3.114 -7.886,3.114 -2.804,0 -4.847,-0.532 -6.127,-1.597 -1.28,-1.066 -1.921,-2.501 -1.921,-4.308 0,-0.916 0.161,-1.677 0.485,-2.285 0.324,-0.607 0.828,-1.124 1.516,-1.557 0.689,-0.43 1.557,-0.794 2.61,-1.091 1.051,-0.297 2.317,-0.607 3.802,-0.93 1.536,-0.324 2.931,-0.682 4.184,-1.073 1.254,-0.389 2.318,-0.881 3.195,-1.476 0.876,-0.592 1.55,-1.32 2.023,-2.183 0.471,-0.862 0.707,-1.927 0.707,-3.195 0,-1.267 -0.249,-2.372 -0.749,-3.316 -0.498,-0.944 -1.198,-1.733 -2.103,-2.366 -0.902,-0.633 -1.981,-1.112 -3.234,-1.436 -1.254,-0.322 -2.648,-0.485 -4.186,-0.485 -4.476,0 -8.263,1.429 -11.365,4.287 l 1.093,1.659 c 1.212,-1.269 2.695,-2.273 4.448,-3.014 1.752,-0.742 3.721,-1.112 5.904,-1.112 2.507,0 4.469,0.458 5.885,1.375 1.415,0.916 2.123,2.304 2.123,4.166 0,0.944 -0.189,1.745 -0.566,2.406 -0.378,0.661 -0.937,1.233 -1.679,1.719 -0.742,0.485 -1.679,0.903 -2.809,1.253 -1.133,0.35 -2.455,0.688 -3.964,1.01 -1.536,0.325 -2.892,0.668 -4.064,1.033 -1.173,0.364 -2.159,0.822 -2.953,1.375 -0.795,0.552 -1.395,1.218 -1.8,2.001 -0.404,0.782 -0.605,1.766 -0.605,2.953 0,1.295 0.248,2.439 0.748,3.437 0.498,0.997 1.192,1.84 2.081,2.528 0.891,0.688 1.955,1.207 3.195,1.556 1.241,0.351 2.616,0.527 4.126,0.527 1.86,0 3.518,-0.278 4.975,-0.829 1.455,-0.554 2.775,-1.396 3.962,-2.529 z"/>
          </g>
        </g>
      </g>
    </g>
  </svg>`;

  renderSvgToConsole(svg, 175, 50); // orig size: 700x200
}

export function renderSvgToConsole(svg: string, width: number, height: number) {
  // Converts raw svg to data
  let svgData = `data:image/svg+xml;base64,${btoa(svg)}`;
  console.log(
    '%c ',
    `
  background-image: url(${svgData});
  padding-bottom: ${height}px;
  padding-left: ${width}px;
  margin: 8px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
`
  );
}
