import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

@Component({
  selector: 'app-blank',
  template: ``
})
export class BlankComponent {}

const routes: Routes = [
  {
    path: '',
    component: BlankComponent
  },
  {
    path: 'sso',
    component: BlankComponent,
    data: {
      allowSmallScreens: true
    },
    children: [
      {
        path: 'featurebase',
        component: BlankComponent,
        data: {
          feature: 'featurebase'
        }
      }
    ]
  },

  {
    path: '**',
    redirectTo: ''
  }
];

@NgModule({
  declarations: [BlankComponent],
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
