import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-mfa-totp',
  templateUrl: './totp.component.html',
  styleUrls: ['./totp.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TotpComponent implements AfterViewInit, OnDestroy {
  private subscriptions: Subscription[] = [];

  public mfaStatus$ = new BehaviorSubject<'verify' | 'setup'>('verify');

  public showQRCode$ = new BehaviorSubject(false);

  public formGroup = this.getFormGroup();

  // Get a reference to the nativelement of an <img> tag
  @ViewChild('qrcode') qrcodeImage: ElementRef;
  @Input() public mfaChallenge: any;
  @Input() public hasError: boolean;
  @Input() public busy: boolean;
  @Output() public mfaData = new EventEmitter<any>();
  @Output() public showLogin = new EventEmitter<any>();

  constructor(private formBuilder: FormBuilder) {}

  ngAfterViewInit(): void {
    if (this.mfaChallenge.mfaConfig?.qrCode) {
      this.mfaStatus$.next('setup');
      const qrCodeDataURL = this.mfaChallenge.mfaConfig.qrCode;
      this.displayQRCode(qrCodeDataURL);
    } else {
      this.mfaStatus$.next('verify');
    }
  }

  private getFormGroup() {
    return this.formBuilder.group({
      code: new FormControl('', [Validators.required, Validators.minLength(6)])
    });
  }

  private displayQRCode(dataurl: string) {
    this.showQRCode$.next(true);

    setTimeout(() => {
      this.qrcodeImage.nativeElement.src = dataurl;
    });
  }

  public onVerify() {
    if (this.formGroup.invalid) {
      return;
    }
    this.mfaData.emit(this.formGroup.value.code);
  }

  public onShowLogin() {
    this.showLogin.emit();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
