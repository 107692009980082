<div class="mb-3 starterText text-center">
  <div class="">
    <b>Sign up for Fresh Projects</b>
  </div>
</div>

<div class="mt-2 text-center" ng-if="!signingup">
  <div class="">
    <form name="regForm">
      <div class="form-floating mb-3">
        <select
          class="form-select"
          id="rServer"
          [class.is-invalid]="
            formGroup.controls.user.controls.server.dirty && !formGroup.controls.user.controls.server.valid
          "
          [formControl]="formGroup.controls.user.controls.server"
        >
          <option selected value="global-uk">Global</option>
          <option value="back4app-uk">UK</option>
          <option value="back4app-sa">SA</option>
          <option value="back4app-aus">AUS</option>
          <option value="back4app-master">MASTER</option>
          <option value="localhost" *ngIf="isLocalhost">localhost</option>
        </select>

        <label for="rServer">Server</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="
            formGroup.controls.user.controls.name.dirty && !formGroup.controls.user.controls.name.valid
          "
          id="rName"
          [formControl]="formGroup.controls.user.controls.name"
        />
        <label for="rName">Name & Surname</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control"
          [class.is-invalid]="
            formGroup.controls.user.controls.company.dirty && !formGroup.controls.user.controls.company.valid
          "
          id="rCompany"
          [formControl]="formGroup.controls.user.controls.company"
        />
        <label for="rCompany">Company</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="email"
          class="form-control"
          name="rEmail"
          id="rEmail"
          [class.is-invalid]="
            formGroup.controls.user.controls.email.dirty && !formGroup.controls.user.controls.email.valid
          "
          [formControl]="formGroup.controls.user.controls.email"
        />
        <label for="rEmail"
          >Work Email
          <span
            class="starterText"
            *ngIf="formGroup.controls.user.controls.email.dirty && !formGroup.controls.user.controls.email.valid"
          >
            valid work email</span
          ></label
        >
      </div>
      <div class="form-floating mb-3">
        <input
          type="password"
          class="form-control"
          [class.is-invalid]="
            formGroup.controls.user.controls.password.dirty && !formGroup.controls.user.controls.password.valid
          "
          id="rPassword"
          [formControl]="formGroup.controls.user.controls.password"
        />
        <label for="rPassword"
          >Password
          <span
            class="starterText"
            *ngIf="formGroup.controls.user.controls.password.dirty && !formGroup.controls.user.controls.password.valid"
            >minimum of 10 characters</span
          ></label
        >
      </div>
    </form>
  </div>
</div>

<div class="mt-2">
  <div class="mb-3">
    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        [formControl]="formGroup.controls.poc"
        id="poc"
        style="cursor: pointer"
      />
      <label class="form-check-label" for="rTerms" style="cursor: pointer">POC</label>
    </div>
  </div>
</div>

<div class="mt-2">
  <div class="\ text-center mb-3">
    <div class="form-group form-check">
      <input
        type="checkbox"
        class="form-check-input"
        [formControl]="formGroup.controls.termsAccepted"
        id="rTerms"
        style="cursor: pointer"
      />
      <label class="form-check-label" for="rTerms" style="cursor: pointer"
        >I agree to the
        <a href="https://www.freshprojects.co.uk/terms" target="_blank">Fresh Projects Terms & Conditions</a></label
      >
    </div>
  </div>
</div>

<div class="mt-2" *ngIf="(busy$ | async) === false">
  <div class="text-center mb-3">
    <button
      type="button"
      [disabled]="!formGroup.valid"
      (click)="onSubmit()"
      class="btn btn-outline-starter"
      style="display: block; width: 100%; padding: 15px"
    >
      Sign Up
    </button>
  </div>
</div>

<div class="mt-5 text-center starterText" *ngIf="busy$ | async">
  <div class="text-center starterText">
    <span class="spinner-border spinner-border-sm"></span>
  </div>
</div>

<div class="mt-1 text-center" *ngIf="error$ | async">
  <div class="error">{{ error$ | async }}</div>
</div>

<div class="mt-3 starterText text-center" style="font-size: 0.8em" ng-if="!error">
  <div class="">
    This will create a <b>new company account.</b><br />If your company already uses Fresh Projects, <br />please speak
    to your line manager.
  </div>
</div>
