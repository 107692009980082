<div class="" style="top: 10%">
  <div class="row mb-1 text-center">
    <div class="col">
      <h3>Migration In Progress</h3>
    </div>
  </div>

  <div class="row mt-3">
    <div class="m-auto col-sm-8 text-center">
      <p>
        In order to improve your overall experience of Fresh Projects, we are busy migrating your company to a regional
        server.
      </p>
      <p><br /></p>
      <p>Please check back in a few minutes.</p>
      <p><br /></p>
      <p>If this issue persists after a few hours please contact support.</p>
    </div>
  </div>
</div>
