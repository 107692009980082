import { environment } from '../environments/environment';
import { AuthEndpoints } from './interfaces';

export const SERVER_STORAGE_NAME = 'fpserver';

export const DEFAULT_AUTH_ENDPOINTS: AuthEndpoints = {
  forgotpass: environment.authEndpoints?.forgotpass || 'fp-auth-forgotpass',
  login: environment.authEndpoints?.login || 'fp-auth-login',
  resetpass: environment.authEndpoints?.resetpass || 'fp-auth-resetpass',
  resetvalidate: environment.authEndpoints?.resetvalidate || 'fp-auth-validatereset',
  server: environment.authEndpoints?.server || 'fp-auth-server'
};

export type AuthEndpointKeys = keyof AuthEndpoints;
