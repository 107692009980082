<ng-container *ngIf="(mfaStatus$ | async) === 'verify'">
  <div class="title">Two-Factor Authentication</div>
  <div class="title-description">
    Your account is protected with two-factor authentication. Please enter the code from your authenticator app.
  </div>
</ng-container>
<ng-container *ngIf="(mfaStatus$ | async) === 'setup'">
  <div class="title">Setup two-factor authentication</div>
  <div class="title-description">
    Use your phone to scan the QR code below using an Authenticator app and enter the verification code to complete
    setup.
  </div>
  <div class="totp-qrcode" [class.visible]="showQRCode$ | async">
    <img #qrcode />
  </div>
</ng-container>

<div class="formgroup needs-validation" [formGroup]="formGroup" novalidate>
  <div class="form-input-group">
    <label for="username" class="form-label">TOTP Code</label>
    <input
      autocomplete="off"
      class="form-control"
      type="text"
      placeholder="Please enter your code"
      formControlName="code"
      [class.is-invalid]="hasError"
      (keyup.enter)="onVerify()"
    />
    <!-- <div class="invalid-feedback">Must be a valid code</div> -->
  </div>
  <ng-content select=".form-errors"></ng-content>

  <div class="form-actions">
    <button class="btn btn-secondary" type="button" (click)="onShowLogin()">Back to login</button>
    <button class="btn btn-primary" type="button" (click)="onVerify()" [disabled]="!formGroup.valid || busy">
      {{ (mfaStatus$ | async) === 'setup' ? 'Verify' : 'Continue' }}
      <span class="spinner-border spinner-border-sm" *ngIf="busy"></span>
    </button>
  </div>
</div>
