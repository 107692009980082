import { AfterViewInit, Component, EventEmitter, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, catchError, concatMap, delay, of, Subject } from 'rxjs';

import { AuthService } from '../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements AfterViewInit {
  public busy$ = new BehaviorSubject<boolean>(true);
  public codeValid$ = new Subject<boolean>();
  public error$ = new BehaviorSubject<string>(null);
  public showResetSent$ = new BehaviorSubject<boolean>(false);
  public formGroup = this.getFormGroup();
  @Output() onShowLogin = new EventEmitter<void>();
  @Output() attemptLogin = new EventEmitter<{ username: string; password: string }>();

  constructor(
    private route: ActivatedRoute,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {}

  private getFormGroup() {
    return this.formBuilder.group({
      username: new FormControl('', []),
      code: new FormControl('', []),
      password: new FormControl('', [Validators.minLength(10)])
    });
  }

  ngAfterViewInit() {
    const params = this.route.snapshot.queryParams;
    if (!params['code'] || !params['email']) {
      this.codeValid$.next(false);
      this.error$.next('Invalid code or email.');
      return;
    }
    this.formGroup.patchValue({
      username: params['email'],
      code: params['code']
    });

    this.authService
      .validateResetCode(params['email'], params['code'])
      .pipe(
        delay(1000),
        catchError((err) => {
          console.error(err);
          this.busy$.next(false);
          this.codeValid$.next(false);
          return of(null);
        })
      )
      .subscribe((response) => {
        this.busy$.next(false);
        if (!response) {
          this.codeValid$.next(false);
          this.error$.next('Invalid code or email.');
          return;
        }
        if (response['error']) {
          this.codeValid$.next(false);
          this.error$.next('Invalid code or email.');
          return;
        }
        this.codeValid$.next(true);
      });
  }

  onRequestPasswordReset() {
    this.error$.next(null);
    this.showResetSent$.next(false);
    this.onRequestPasswordReset();
  }

  onSendPasswordReset() {
    this.error$.next(null);
    this.busy$.next(true);
    this.authService
      .forgotPassword(this.formGroup.value.username)
      .pipe(
        catchError((err) => {
          console.error(err);
          this.busy$.next(false);
          this.error$.next('Unable to send reset email.  Please contact support or try again.');
          return of(null);
        })
      )
      .subscribe((response) => {
        this.showResetSent$.next(true);
        this.busy$.next(false);
      });
  }

  onResetPassword() {
    this.error$.next(null);
    if (this.formGroup.invalid) {
      this.error$.next('Please complete the form.');
      return;
    }
    this.busy$.next(true);
    const data = this.formGroup.value;
    this.authService
      .resetPassword(data.username, data.code, data.password)
      .pipe(
        catchError((err) => {
          this.busy$.next(false);
          this.error$.next(err);
          return of(null);
        })
      )
      .subscribe((response) => {
        if (!response) {
          this.busy$.next(false);
          this.error$.next('Unable to reset password.');
          return;
        }
        if (response['error']) {
          this.busy$.next(false);
          this.error$.next(response['message'] ? response['message'] : response['error']);
          return;
        }
        this.error$.next(null);
        this.attemptLogin.emit({ username: data.username, password: data.password });
      });
  }

  onReturnToLogin() {
    // refresh page without using router
    window.location.href = window.location.pathname;
  }
}
